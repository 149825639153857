module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Makoto Dejima | Designer and Front-End Developer","short_name":"Makoto Dejima","start_url":"/","background_color":"#ffffff","theme_color":"rgb(255, 255, 248)","display":"minimal-ui","icon":"src/images/mdfavicon.svg","icons":[{"src":"/icons/icon-192x192.png","sizes":"192x192","type":"image/svg"},{"src":"/icons/icon-512x512.png","sizes":"512x512","type":"image/svg"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"07cc535bdcf7d260d358e58becfbe2e3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-135555172-1","head":false,"anonymize":true,"respectDNT":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
