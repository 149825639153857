// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-wear-index-tsx": () => import("./../../../src/pages/wear/index.tsx" /* webpackChunkName: "component---src-pages-wear-index-tsx" */),
  "component---src-pages-work-index-tsx": () => import("./../../../src/pages/work/index.tsx" /* webpackChunkName: "component---src-pages-work-index-tsx" */),
  "component---src-pages-work-manhattan-portage-tsx": () => import("./../../../src/pages/work/manhattan-portage.tsx" /* webpackChunkName: "component---src-pages-work-manhattan-portage-tsx" */),
  "component---src-pages-work-mera-tsx": () => import("./../../../src/pages/work/mera.tsx" /* webpackChunkName: "component---src-pages-work-mera-tsx" */),
  "component---src-pages-work-naotatsu-kaku-tsx": () => import("./../../../src/pages/work/naotatsu-kaku.tsx" /* webpackChunkName: "component---src-pages-work-naotatsu-kaku-tsx" */),
  "component---src-pages-work-robotex-tsx": () => import("./../../../src/pages/work/robotex.tsx" /* webpackChunkName: "component---src-pages-work-robotex-tsx" */),
  "component---src-pages-work-satte-tsx": () => import("./../../../src/pages/work/satte.tsx" /* webpackChunkName: "component---src-pages-work-satte-tsx" */),
  "component---src-pages-work-soreike-tsx": () => import("./../../../src/pages/work/soreike.tsx" /* webpackChunkName: "component---src-pages-work-soreike-tsx" */),
  "component---src-pages-work-the-light-of-tokyo-tsx": () => import("./../../../src/pages/work/the-light-of-tokyo.tsx" /* webpackChunkName: "component---src-pages-work-the-light-of-tokyo-tsx" */)
}

